import {
    ProductCardComponent as SourceProductCardComponent,
} from 'SourceComponent/ProductCard/ProductCard.component';

import { scrollToTop } from '../../util/Browser/Browser';
import './ProductCard.style';

/** @namespace AlmusbahblendPwa/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCardComponent {
    // TODO implement logic
    handleLinkClick() {
        const { registerSharedElement, isPlp } = this.props;
        console.log("Product card props: ", this.props);
        // if product values got then call: triggerDLProductClick
        
        if (!isPlp) {
            scrollToTop();
        }

        registerSharedElement(this.imageRef);
    }
}

export default ProductCardComponent;
